// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    cargando: false,
    cargandoHistorial: false,
    pedidos: [],
    pedidosInicio: {
        pedidos: []
    },
    pedidosAnalisis: [],
    pedidosHistorial: [],
    pedidoDetails: [],
    pedidosToExport: [],
    totalPedidosHistorial: 0,
    columns: [],
    carrito: [],
    pedidosFiltrados: [],
    filtros: {
        fechaInicio: new Date(new Date().setMonth(new Date().getMonth() - 11)).toLocaleDateString('zh-Hans-CN'),
        fechaFin: new Date().toLocaleDateString('zh-Hans-CN'),
        cliente: null,
        takeaway: 10,
        metodo_pago: 0,
        estado: null
    },
    precioEnvio: 0,
    totalPedido: 0,
    selectedPedido: null,
    currentSucursal: null,
    validAddress: false,
    descuentoActivo: false,
    descuentoTipo: 'porcentaje',
    descuentoValor: {
        porcentaje: 5,
        efectivo: 100
    },
    respuestaImprimir: null
};

const slice = createSlice({
    name: 'pedidos',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // VALID ADDRESS
        updateValidAddressSuccess(state, action) {
            state.validAddress = action.payload;
        },

        // UPDATE PRECIO ENVIO
        updatePrecioEnvio(state, action) {
            state.precioEnvio = action.payload;
        },

        // UPDATE TOTAL PEDIDO
        updateTotalPedidoSuccess(state, action) {
            state.totalPedido = action.payload;
        },

        // SET CURRENT SUCURSAL
        setCurrentSucursalSuccess(state, action) {
            state.currentSucursal = action.payload;
        },

        // GET PEDIDOS
        getPedidosSuccess(state, action) {
            state.pedidos = action.payload;
        },

        // GET PEDIDOS
        getPedidosHistorialSuccess(state, action) {
            state.pedidosHistorial = action.payload;
        },

        getPedidoDetailsSuccess(state, action) {
            state.pedidoDetails = action.payload;
        },

        getPedidosToExportSuccess(state, action) {
            state.pedidosToExport = action.payload;
        },

        // LIMPIAR PEDIDOS HISTORIAL
        limpiarPedidosHistorialSuccess(state) {
            state.pedidosHistorial = [];
        },

        limpiarDetallesPedidoSuccess(state) {
            state.pedidoDetails = [];
        },

        // GET PEDIDOS INICIO
        getPedidosInicioSuccess(state, action) {
            state.pedidosInicio = action.payload;
        },

        // GET PEDIDOS ANALISIS
        getPedidosAnalisisSuccess(state, action) {
            state.pedidosAnalisis = action.payload;
        },

        // SET TOTAL PEDIDOS HISTORIAL
        setTotalPedidosHistorial(state, action) {
            state.totalPedidosHistorial = action.payload;
        },

        // SET TOTAL PEDIDOS TO EXPORT
        setPedidosToExport(state, action) {
            state.pedidosToExport = action.payload;
        },

        // GET COLUMNS
        getColumnsSuccess(state, action) {
            state.columns = action.payload;
        },

        // LIMPIAR PEDIDOS
        deletePedidosSuccess(state) {
            state.pedidos = [];
        },

        // UPDATE COLUMN ITEM ORDER
        updateColumnPedidosOrderSuccess(state, action) {
            state.columns = action.payload;
        },

        // FILTRAR SUCCESS
        filtrarPedidosSuccess(state, action) {
            state.pedidosFiltrados = action.payload;
        },
        // SELECT PEDIDO
        selectPedidoSuccess(state, action) {
            state.selectedPedido = action.payload;
        },

        // SELECT FILTROS
        selectFiltrosSuccess(state, action) {
            state.filtros = action.payload;
        },

        // DELETE PEDIDO
        deleteSelectedPedidoSuccess(state) {
            state.selectedPedido = false;
        },

        // ACEPTAR PEDIDO
        aceptarPedidoSuccess(state) {
            state.error = null;
        },

        // ENVIAR PEDIDO
        enviarPedidoSuccess(state) {
            state.error = null;
        },

        // CANCELAR PEDIDO
        cancelarPedidoSuccess(state) {
            state.error = null;
        },

        // RECIBIR PEDIDO
        recibirPedidoSuccess(state) {
            state.error = null;
        },

        // RECHAZAR PEDIDO
        rechazarPedidoSuccess(state) {
            state.error = null;
        },

        // AGREGAR PRODUCTO AL CARRITO
        agregarProductoCarritoSuccess(state, action) {
            const carritoActualizado = [...state.carrito, action.payload];
            state.carrito = carritoActualizado;
        },

        // ELIMINAR PRODUCTO DEL CARRITO
        eliminarProductoCarritoSuccess(state, action) {
            const carritoActualizado = state.carrito.filter((producto) => producto.producto !== action.payload);
            state.carrito = carritoActualizado;
        },

        // LIMPIAR CARRITO
        limpiarCarritoSuccess(state) {
            state.carrito = [];
        },

        // CAMBIAR ESTADO DE DESCUENTO
        cambiarDescuentoEstadoSuccess(state, action) {
            state.descuentoActivo = action.payload;
        },

        // CAMBIAR TIPO DE DESCUENTO
        cambiarTipoDescuentoSuccess(state, action) {
            state.descuentoTipo = action.payload;
        },

        // CAMBIAR VALOR DEL DESCUENTO
        cambiarValorDescuentoSuccess(state, action) {
            state.descuentoValor = action.payload;
        },

        // CREAR PEDIDO MANUAL
        crearPedidoManualSuccess(state) {
            state.error = null;
        },

        // SET CARGANDO = TRUE
        setCargandoSuccess(state) {
            state.cargando = true;
        },

        // SET CARGANDO = FALSE
        unsetCargandoSuccess(state) {
            state.cargando = false;
        },

        // SET CARGANDO = TRUE
        setCargandoHistorialSuccess(state) {
            state.cargandoHistorial = true;
        },

        // SET CARGANDO = FALSE
        unsetCargandoHistorialSuccess(state) {
            state.cargandoHistorial = false;
        },

        // IMPRIMIR PEDIDO
        imprimirPedidoSuccess(state, action) {
            state.respuestaImprimir = action.payload;
        },

        // LIMPIAR RESPUESTA IMPRIMIR
        limpiarRespuestaImprimirSuccess(state) {
            state.respuestaImprimir = null;
        }
    }
});

// Reducer
export default slice.reducer;

// -----------------------------------------------------

export function setCargando() {
    return async () => {
        try {
            dispatch(slice.actions.setCargandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetCargando() {
    return async () => {
        try {
            dispatch(slice.actions.unsetCargandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setCargandoHistorial() {
    return async () => {
        try {
            dispatch(slice.actions.setCargandoHistorialSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetCargandoHistorial() {
    return async () => {
        try {
            dispatch(slice.actions.unsetCargandoHistorialSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setCurrentSucursalId(sucursalId) {
    return async () => {
        try {
            dispatch(slice.actions.setCurrentSucursalSuccess(sucursalId));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateValidAddress(coordinates) {
    return async () => {
        try {
            const response = await axios.post('/verificarZonaEntrega', {
                coordinates
            });

            if (response.data.status === 200) {
                dispatch(slice.actions.updateValidAddressSuccess(true));
                dispatch(slice.actions.updatePrecioEnvio(response.data.precio));
            } else {
                dispatch(slice.actions.updateValidAddressSuccess(false));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPedidos() {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.get('/getPedidosNegocio');
            dispatch(slice.actions.getPedidosSuccess(response.data.pedidos));
            dispatch(unsetCargando());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deletePedidos() {
    return async () => {
        try {
            dispatch(slice.actions.deletePedidosSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getColumnsPedidosOrder(pedidos) {
    return async () => {
        try {
            const pedidosIngresados = pedidos.filter((pedido) => pedido.estado === 'INGRESADO').map((pedido) => pedido.id);
            const pedidosEnPreparacion = pedidos.filter((pedido) => pedido.estado === 'PREPARANDO').map((pedido) => pedido.id);
            const pedidosCompletados = pedidos.filter((pedido) => pedido.estado === 'EN_CAMINO').map((pedido) => pedido.id);

            const columnsPedidos = [
                {
                    id: 'INGRESADO',
                    title: 'Ingresados',
                    itemIds: pedidosIngresados
                },
                {
                    id: 'PREPARANDO',
                    title: 'En preparacion',
                    itemIds: pedidosEnPreparacion
                },
                {
                    id: 'EN_CAMINO',
                    title: 'Para entregar',
                    itemIds: pedidosCompletados
                }
            ];

            dispatch(slice.actions.getColumnsSuccess(columnsPedidos));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateColumnPedidosOrder(columns) {
    return async () => {
        try {
            // const response = await axios.post('/api/kanban/update-item-order', { columns });
            dispatch(slice.actions.updateColumnPedidosOrderSuccess(columns));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function agregarProductoCarrito(carrito) {
    return async () => {
        try {
            dispatch(slice.actions.agregarProductoCarritoSuccess(carrito));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function eliminarProductoCarrito(id) {
    return async () => {
        try {
            dispatch(slice.actions.eliminarProductoCarritoSuccess(id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function limpiarCarrito() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarCarritoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function cambiarDescuentoEstado(estado) {
    return async () => {
        try {
            dispatch(slice.actions.cambiarDescuentoEstadoSuccess(estado));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function cambiarTipoDescuento(tipo) {
    return async () => {
        try {
            dispatch(slice.actions.cambiarTipoDescuentoSuccess(tipo));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function cambiarValorDescuento(nuevoValor) {
    return async () => {
        try {
            dispatch(slice.actions.cambiarValorDescuentoSuccess(nuevoValor));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateTotalPedido(nuevoValor) {
    return async () => {
        try {
            dispatch(slice.actions.updateTotalPedidoSuccess(nuevoValor));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function crearPedidoManual(pedidoInfo) {
    return async () => {
        try {
            await axios.post('/crearPedidoDashboard', pedidoInfo);
            dispatch(slice.actions.crearPedidoManualSuccess());
            dispatch(getPedidos());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// HISTORIAL PEDIDOS

export function getHistorialPedidos(data) {
    return async () => {
        try {
            dispatch(setCargandoHistorial());
            const response = await axios.post('/getHistorialPedidos', {
                fecha_inicio: data.fechaInicio,
                fecha_fin: data.fechaFin,
                pagina: data.pagina,
                filtros: data.filtros,
                cantidad: data.cantidad ? data.cantidad : 10,
                orden: 'pedidos.id',
                tipo_orden: 'desc',
                busqueda: data?.busqueda
            });

            dispatch(slice.actions.getPedidosHistorialSuccess(response.data.pedidos));
            dispatch(slice.actions.setTotalPedidosHistorial(response.data.total_pedidos));
            dispatch(unsetCargandoHistorial());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // console.log(error);
        }
    };
}

export function getPedidoDetails(pedidoId) {
    return async () => {
        try {
            dispatch(slice.actions.limpiarDetallesPedidoSuccess());
            const response = await axios.post('/getPedidoDetails', {
                id_pedido: pedidoId
            });

            dispatch(slice.actions.getPedidoDetailsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function limpiarPedidosHistorial() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarPedidosHistorialSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPedidosToExport(data) {
    return async () => {
        try {
            dispatch(setCargandoHistorial());
            const response = await axios.post(`/getPedidosToExport`, {
                fecha_inicio: data.fechaInicio,
                fecha_fin: data.fechaFin,
                filtros: data,
                orden: 'pedidos.id',
                tipo_orden: 'desc'
            });

            // this response is an archive with the customers to export. It is not an array of customers
            // download the archive and then parse it to an array of customers

            dispatch(slice.actions.setPedidosToExport(response.data.pedidos));
            dispatch(unsetCargandoHistorial());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPedidosInicio() {
    return async () => {
        try {
            const response = await axios.post('/getPedidosInicio');
            dispatch(slice.actions.getPedidosInicioSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPedidosAnalisis(data) {
    return async () => {
        try {
            const response = await axios.post('/getPedidosAnalisis', {
                fecha_inicio: data.fechaInicio,
                fecha_fin: data.fechaFin
            });
            dispatch(slice.actions.getPedidosAnalisisSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // console.log(error);
        }
    };
}

export function getAnalisis(data) {
    dispatch(setCargando());
    return async () => {
        try {
            const sucursal = sessionStorage.getItem('id_sucursal');
            const response = await axios.post('/getAnalisis', {
                id_sucursal: sucursal,
                fecha_inicio: data.fechaInicio,
                fecha_fin: data.fechaFin
            });
            dispatch(slice.actions.getPedidosAnalisisSuccess(response.data.data));
            dispatch(unsetCargando());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(unsetCargando());
        }
    };
}

export function selectPedido(pedido) {
    return async () => {
        try {
            dispatch(slice.actions.selectPedidoSuccess(pedido));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filtrarPedidos(filtros, pedidos) {
    return async () => {
        try {
            // console.log(filtros);

            // pasar a string el estado
            if (typeof filtros === 'string' || filtros instanceof String) {
                const estadoString = filtros.toString();
                // eslint-disable-next-line eqeqeq
                if (estadoString != 'TODOS') {
                    // console.log(pedidos);
                    dispatch(slice.actions.filtrarPedidosSuccess(pedidos.filter((pedido) => pedido.estado === estadoString)));
                } else {
                    // filtrar por los distintos campos
                    dispatch(slice.actions.filtrarPedidosSuccess(pedidos));
                }
            } else {
                // recorrer los filtros e ir filtrando los pedidos por cada filtro

                try {
                    // recorrer filtros y si es diferente de undefined pushearlo en un json
                    const filtrosPedidos = {};
                    Object.keys(filtros).forEach((key) => {
                        if (filtros[key] !== undefined) {
                            filtrosPedidos[key] = filtros[key];
                        }
                    });

                    const filtrosArray = Object.keys(filtrosPedidos);
                    const pedidosFiltrados = filtrosArray.reduce(
                        // solo si el filtro no esta vacio
                        (acc, filtro) => acc.filter((pedido) => pedido[filtro] === filtrosPedidos[filtro]),
                        pedidos
                    );
                    // console.log('filtrados', pedidosFiltrados);
                    dispatch(slice.actions.filtrarPedidosSuccess(pedidosFiltrados));
                } catch (error) {
                    // console.log(error);
                    dispatch(slice.actions.hasError(error));
                }
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteSelectedPedido() {
    return async () => {
        try {
            dispatch(slice.actions.deleteSelectedPedidoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// -------------------------- ACCIONES FLUJO ---------------------------

export function aceptarPedido(info) {
    return async () => {
        try {
            await axios.post('/pedidoAceptado', info);

            dispatch(slice.actions.aceptarPedidoSuccess());
            dispatch(getPedidos());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function enviarPedido(info) {
    return async () => {
        try {
            await axios.post('/pedidoEnviado', info);

            dispatch(slice.actions.enviarPedidoSuccess());
            dispatch(getPedidos());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function cancelarPedido(info) {
    return async () => {
        try {
            await axios.post('/pedidoCancelado', info);

            dispatch(slice.actions.cancelarPedidoSuccess());
            dispatch(getPedidos());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function recibirPedido(info) {
    return async () => {
        try {
            await axios.post('/pedidoRecibido', info);

            dispatch(slice.actions.recibirPedidoSuccess());
            dispatch(getPedidos());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function rechazarPedido(info) {
    return async () => {
        try {
            await axios.post('/pedidoRechazado', info);

            dispatch(slice.actions.rechazarPedidoSuccess());
            dispatch(getPedidos());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function imprimirPedidoNegocio(data) {
    dispatch(setCargando());
    return async () => {
        try {
            // console.log(data);
            const response = await axios.post('/datosPedidoImprimir', {
                pedido_id: data.pedidoId,
                id_sucursal: data.sucursalId
            });
            // console.log(response);

            dispatch(slice.actions.imprimirPedidoSuccess(response.data.pedido));
            dispatch(unsetCargando());
        } catch (error) {
            dispatch(unsetCargando());
            dispatch(slice.actions.hasError(error));
            console.log(error);
        }
    };
}

export function limpiarRespuestaImprimir() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarRespuestaImprimirSuccess());
            // dispatch(getPedidos());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setFiltros(filtros) {
    return async () => {
        // console.log(filtros);
        try {
            // console.log(filtros);
            dispatch(slice.actions.selectFiltrosSuccess(filtros));
        } catch (error) {
            // console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
